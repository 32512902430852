/*
  This is where the magic happens and creates the custom field extension.

  Note that if you're writing extensions part of a separate plugin,
  then please use `plugin.provide` from there instead and export it part of your `plugin.ts` rather than re-using the `scaffolder.plugin`.
*/

import {scaffolderPlugin,} from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { ChannelPickerExtension } from './ChannelExtension';
import { DomainSystemPicker } from './DomainSystemPicker';
import { SystemPicker } from './SystemPicker';
import { CloudVPCRegionExtension } from './CloudVPCRegionPicker';
import { EksVPCRegionSubnetExtension, validateSubnetValidation } from './EksVPCRegionSubnetMultiPicker';
  
  export const ChannelPicker = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'ChannelPicker',
      component: ChannelPickerExtension,
    }),
  );

  export const SystemPickerField = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'SystemPickerField',
      component: SystemPicker,
    }),
  );

  export const DomainSystemPickerField = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'DomainSystemPickerField',
      component: DomainSystemPicker,
    }),
  );

  export const CloudVPCRegionPickerField = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'CloudVPCRegionPickerField',
      component: CloudVPCRegionExtension,
    }),
  );

  export const EksVPCRegionSubnetMultiPickerField = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'EksVPCRegionSubnetMultiPickerField',
      component: EksVPCRegionSubnetExtension,
      validation:validateSubnetValidation
    }),
  );